/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */

html,
button,
input,
select,
textarea {
  color: #222;
}

body {
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

@font-face {
  font-family: 'TT Commons Regular';
  src: url('tt_commons_regular.bfeb0e39.woff2') format('woff2'),
    url('tt_commons_regular.b9fe4210.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons DemiBold';
  src: url('tt_commons_demibold.446b837e.woff2') format('woff2'),
    url('tt_commons_demibold.05931674.woff') format('woff');
}

body {
  background-color: #ff4051;
  background-image: url('bg.40aab42b.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: sans-serif;
}

.wrapper {
  max-width: 760px;
  margin: 0 auto;
}

span.wrapper {
  display: block;
}

label {
  font-family: 'TT Commons Regular', sans-serif;
  font-size: 1em;
  text-transform: lowercase;
}

input,
select {
  display: block;
  width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #f0f0f0;
  outline: 0;
  border: 0;
}

option {
  padding: 0 10px;
  background-color: #f0f0f0;
}

button {
  padding: 5px 10px;
  font-family: 'TT Commons Regular', sans-serif;
  font-size: 1.6em;
  color: white;
  background-color: #ff4051;
  border: 0;
  outline: 0;
}

button[type='reset'] {
  background: transparent;
  color: #ff4051;
}

button[type='reset']:focus {
  color: black;
}

header {
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  font-family: 'TT Commons Regular', sans-serif;
  font-size: 1.2em;
}

header img {
  width: 190px;
  margin: 22px;
  vertical-align: bottom;
}

header .page-title {
  display: inline-block;
  margin: 28px 0;
  vertical-align: bottom;
}

.form-buttons {
  text-align: right;
}

#signature-builder {
  margin: 60px 0;
}

#signature-builder h3 {
  background-color: #4e4e4e;
  margin-top: 20px;
  color: white;
  font-family: 'TT Commons DemiBold', 'Arial Black', sans-serif;
  font-weight: 400;
  font-size: 2.2em;
  cursor: pointer;
}

#signature-details-submit:disabled {
  opacity: 0.5;
}

#signature-builder h3.disabled span {
  opacity: 0.5;
}

#signature-builder > div {
  background-color: white;
}

#output-preview {
  display: block;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 22px 0;
  font-family: monospace;
  border-width: 1px;
  border-style: dashed;
  border-color: gray;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: '';
  display: block;
  width: 0;
  height: 150%;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/*# sourceMappingURL=index.7f499fab.css.map */
